/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import Button from '../../components/button/button';
import './styles/_index.scss';
import { Link } from "gatsby"

const HomeBookForm = () => {
    return (
        <div className="form home-book-form">
                <div className="form__row">
                  <label htmlFor="postcode" className="form__label">
                    Find your property
                  </label>

                  <input
                    type="text"
                    id="postcode"
                    name="postcode"
                    placeholder="Start typing your postcode"
                    className="form__field"
                    required
                  />
                </div>

                <div className="form__actions">
                  {/* <Button link={`/property-valuation/instant-valuation/`} className="btn--outline-darkblue">Get Started</Button> */}
                  <Link className="linkbtn" to="/property-valuation/instant-valuation/">
                  <button className="btn btn--outline-darkblue"><div>Get Started</div><i class="icon-arrow-right"></i></button>
                  </Link>
                </div>
            </div>
    )
}

export default HomeBookForm;
