/**
 * External Dependencies
 */
 import React, { useState, useEffect } from "react"
 import ReactPlayer from 'react-player';
 import { isIOS } from 'react-device-detect';
 import { Link } from "gatsby"
 
 
 /**
  * Internal Dependencies
  */
 import Portal from "../portal/portal";
 import "./styles/_index.scss";
 import ImageTransform from "../common/ggfx-client/module/components/image-transform";
 import getVideoId from 'get-video-id';
 import $ from "jquery";
 
 const VideoPlaceholder = ({ image, setIsOpen, isOpen, imagetype, ggfxname, altid, imageprocess, imageid, videourl, html5video, linkto, videoTour, propertyLink }) => {
   const [play, setPlayVideo] = useState(false);
   //const playvideo = () => setPlayVideo(true);
   const videoid = videourl && getVideoId(videourl);
   var video_url= ""
   if(videoid) {
       video_url = "https://www.youtube-nocookie.com/embed/" + videoid.id;
   }
   //console.log('aaaaaaaaaa', play)
   useEffect(() => {
    $("#video1").bind("ended", function() {
        //alert('test');
        setPlayVideo(false);
     });
    });
   return (
     <div className="video-placeholder">
       {imagetype === "GGFX" ?
       <Link to ={propertyLink?propertyLink:""}>
         <ImageTransform imagesources={image} renderer="srcSet" imagename={ggfxname} attr={{ alt: altid}} imagetransformresult={imageprocess} id={imageid}/>  
       </Link>
       :
       <Link to ={propertyLink?propertyLink:""}>
         <img src={image} alt={altid} />
       </Link>
       }
       {
         html5video?.length > 0 &&
         <div className="play-icon" onClick={() => { setIsOpen(true); setPlayVideo(true);}}>
           <i className="icon-play-btn"></i>
         </div>
       }
       {isOpen && play &&
       <div className="html5video">
           <a href="javascript:;" className="closehtml" onClick={() => { setPlayVideo(false);}}>
            <i className="icon-close"></i>
           </a>
            <video id="video1" width="400" controls={true} autoPlay={isOpen?true:false} muted={false} playsInline >
                <source src={html5video} type="video/mp4"/>
            </video>
        </div>
        }
     </div>
   )
 }
 
 export default VideoPlaceholder;