/**
 * External dependencies
 */
import React, { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import Button from "../button/button"
import { useStaticQuery, graphql } from "gatsby"

/**
 * Internal dependencies
 */
import HomeBookForm from "../../blocks/home-book-form/home-book-form"
import "./styles/_index.scss"
import VideoPlaceholder from "../video-placeholder/html-video-placeholder"
import { openPopupWidget } from "react-calendly";
import $ from 'jquery';
import { AltSiteName } from "../utils"
const HomeBook = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClickCalendly = (url) => openPopupWidget({ url });
  const data = useStaticQuery(graphql`
    query MyQuery {
      glstrapi {
        globalConfig {
          Book_A_Home_Valuation {
            Tile_Title
            Tile_Description
            Tile_Video_URL
            Tile_Image {
              url
            }
          }
        }
      }

      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          dir: { regex: "/home-book/images/" }
          name: { eq: "book-valuation" }
        }
      ) {
        edges {
          node {
            childrenImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)

 

  var fetch_data = data.glstrapi.globalConfig.Book_A_Home_Valuation;
  useEffect(() => {
    if(props.title != "Home") {
      $('.footer').addClass('inner-footer-val');
    }
  });
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const GTMTracker = (event) => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          'event': 'Book a Video Call',
          'formType': url,
          'formId': 'Book a Video',
          'formName': 'Book a Video',
          'formLabel': 'Book a Video'
      });
    }
}
var TileImage= data.allFile.edges[0].node.childrenImageSharp[0].fluid.src
if(fetch_data.Tile_Image) {
  TileImage = fetch_data.Tile_Image.url
}
if(props.image && props.image.url) {
  TileImage = props.image.url
}
  return (
    <div className={props.title == 'Home' ? 'home-book-wrapper':"home-book-wrapper inner-book"}>
      <div className="home-book" data-aos="fade-up">
        <div className="shell">
          <Row className="home-book__row">
            <Col className="home-book__video">
              <VideoPlaceholder
                isOpen={isOpen}
                altid={fetch_data.Tile_Title + AltSiteName}
                setIsOpen={setIsOpen}
                html5video={props.video && props.video.url}
                image={TileImage}
              />
              <Button className="btn--fill-white">
                Book a Video Call
              </Button>
              <button onClick={() => {GTMTracker();onClickCalendly("https://calendly.com/lambornhill/30min");}}  className="btn btn--fill-white"><div>Book an Appointment</div><i className="icon-arrow-right"></i></button>
            </Col>

            <Col
              lg={{
                span: 5,
                offset: 1,
              }}
              className="home-book__content"
            >
              <div className="home-book__text">
                <h2>{fetch_data.Tile_Title}</h2>

                <p>
                  {fetch_data.Tile_Description}
                </p>
              </div>
              <HomeBookForm />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default HomeBook
